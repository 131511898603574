import React from "react";

const GameDiscription = () => {
  const howToUseContent = `
    1.Read the words 
The first step is to carefully read and understand the words provided in the Connections Archive New York Times Word Game. Take your time to understand each word and think about what it means in relation to the Connections Archive Puzzle. If you need assistance, feel free to use Google or other resources to help you understand the meaning of the words.

    2.Look for a Common Thing
In the Connections Archive game, after reading and understanding the words, your next step is to find the common theme that connects them. During this step, you can ask yourself questions like: Do the words belong to the same group? Are they similar in some way? Do they have a connection to a specific topic or idea? Look for relationships or similarities between the words to find the common theme in the Connections Archive. 
   3.Select & Submit Your Answer
Once you have identified the common theme and found the four words that fit within that theme, it's time to make your selection. After selecting the four words related to the Connections Archive, you need to submit your answer. You can do that by clicking the Submit button.  `;

  const faqContent = [
    {
      question: "What is the Connections Archive?",
      answer: "The Connections Archive is a gaming platform that allows players to explore themes across various games. The Connections Archive aims to help players discover and understand connections while enjoying puzzle-solving experiences. By using the Connections Archive, players can gain insights into how different games relate to one another."
    },
    {
      question: "What are the rules of the Connections Archive?",
      answer: "The rules of the Connections Archive are simple:\n1. Browse our game catalog in the Connections Archive.\n2. Choose a game that interests you from the Connections Archive.\n3. Read the game description and connection details.\n4. Find connections between this game and others in the Connections Archive.\n5. Share your insights or explore discoveries from other players in the Connections Archive.\n6. New games and connections are continuously added to the Connections Archive."
    },
    {
      question: "What is the objective of the Connections Archive?",
      answer: "The objective of the Connections Archive is to help players uncover and understand connections between games, exploring game design and the gaming industry through the Connections Archive."
    },
    {
      question: "Can I play unlimited games on the Connections Archive?",
      answer: "Yes, the Connections Archive offers unlimited access to browse and explore connections anytime without any restrictions."
    },
    {
      question: "Is the Connections Archive suitable for children?",
      answer: "Yes, the Connections Archive is suitable for all ages and can enhance logical thinking and creativity, though younger children may need adult supervision while using the Connections Archive."
    },
    {
      question: "Is the Connections Archive free?",
      answer: "Yes, the Connections Archive is completely free, accessible on any device at no cost."
    },
    {
      question: "Can I challenge my friends on the Connections Archive?",
      answer: "Currently, the Connections Archive doesn't have a direct multiplayer mode. However, you can share interesting connections you've discovered with friends or explore the Connections Archive together, comparing your insights."
    }
  ];

  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        {/*<header className="text-center mb-12">*/}
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Welcome to the Connections Archive</h1>
        {/*</header>*/}

        <section className="bg-white shadow-md rounded-lg p-6 mb-8">
          <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">How to Use the Connections Archive</h2>
          <p className="text-gray-600 whitespace-pre-line">{howToUseContent}</p>
        </section>

        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">Frequently Asked Questions (FAQ)</h2>
          <ul className="space-y-6">
            {faqContent.map((item, index) => (
              <li key={index} className="border-b border-gray-200 pb-4 last:border-b-0">
                <h3 className="text-xl font-semibold text-gray-700 mb-2">{item.question}</h3>
                <p className="text-gray-600 whitespace-pre-line">{item.answer}</p>
              </li>
            ))}
          </ul>
        </section>
      </div>
      <div>
        <p className="text-center text-gray-500 mt-4">
          Connectionsarchive.org© 2024


        </p>
        <p className="text-center text-gray-500 mt-4">Connectionsarchive.org is not affiliated with "Connections" by NYTimes in any way</p>
      </div>
    </div>
  );
};

export default GameDiscription;
