import React from "react";
import { HashRouter, Routes, Route,useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Header from "../Header";
import Game from "../Game";
import GameDiscription from "../GameDiscription";

import { Toaster } from "../ui/toaster";
import PuzzleDataProvider from "../../providers/PuzzleDataProvider";
import GameStatusProvider from "../../providers/GameStatusProvider";

// 新增页面组件
import ConnectionsHintToday from "../ConnectionsHintToday";
// import Contact from "../Contact";


function App() {
  const location = useLocation();

  React.useEffect(() => {
    // 可以在这里执行任何需要的副作用
  }, [location]);

  const canonicalUrl = `https://www.connectionsarchive.org/#${location.pathname}`;
  return (
    <>
      {/*<HashRouter>*/}
        <Helmet>
          <link rel="canonical" href={canonicalUrl} />
          {/* Open Graph Meta Tags */}
          <meta property="og:title" content="Connections Archive - Explore and Play" />
          <meta property="og:description" content="Explore our collection of past and present Connections puzzles, without spoilers!" />
          <meta property="og:image" content="https://www.connectionsarchive.org/images/social-share-image.jpg" />
          <meta property="og:url" content="https://www.connectionsarchive.org" />
          <meta property="og:type" content="website" />

          {/* Twitter Card Meta Tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Connections Archive - Explore and Play" />
          <meta name="twitter:description" content="Explore our collection of past and present Connections puzzles, without spoilers!" />
          <meta name="twitter:image" content="https://www.connectionsarchive.org/images/social-share-image.jpg" />
          <meta name="twitter:url" content="https://www.connectionsarchive.org" />
        </Helmet>
        <PuzzleDataProvider>
          <GameStatusProvider>
            <div className="wrapper">
              <Toaster />
              <Header />
              <Routes>
                {/* 主页面路由 */}
                <Route path="/" element={
                  <>
                    <Game />
                    <GameDiscription/>
                  </>
                } />
                {/* 新增路由 */}
                <Route path="/ConnectionsHintToday" element={<ConnectionsHintToday />} />
                {/*<Route path="/contact" element={<Contact />} />*/}
              </Routes>
            </div>
          </GameStatusProvider>
        </PuzzleDataProvider>
      {/*</HashRouter>*/}
    </>
  );
}

export default App;
