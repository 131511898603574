import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";

function Header() {
  return (
    <header className="bg-gray-50 py-4 px-6 shadow-sm">
      <div className="w-full flex justify-center">
        <div className="w-[60%] min-w-[40%] flex items-center justify-between">
          {/*<h1 className="font-space-mono text-xl">*/}
            <Link to="/" className="text-gray-800 hover:text-gray-600 transition-colors flex items-center">
              <img
                src={logo}
                alt="Connections Archive Logo"
                className="h-8 w-auto"
              />
            </Link>
          {/*</h1>*/}
          <Link
            to="/connectionsHintToday"
            className="text-gray-800 hover:text-gray-600 transition-colors text-xl"
          >
            Hint
          </Link>
        </div>
      </div>
    </header>
  );
}

export default Header;
