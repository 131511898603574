import React from "react";
import { shuffleGameData } from "../../lib/game-helpers";
import GameGrid from "../GameGrid";
import NumberOfMistakesDisplay from "../NumberOfMistakesDisplay";
import GameLostModal from "../modals/GameLostModal";
import GameWonModal from "../modals/GameWonModal";

import { Separator } from "../ui/separator";
import ConfettiExplosion from "react-confetti-explosion";

import { PuzzleDataContext } from "../../providers/PuzzleDataProvider";
import { GameStatusContext } from "../../providers/GameStatusProvider";
import GameControlButtonsPanel from "../GameControlButtonsPanel";

import ViewResultsModal from "../modals/ViewResultsModal";
import { toggleDataSource } from "../../lib/time-utils";
function Game() {
  const { gameData, categorySize, numCategories,time,todayIndex } =
    React.useContext(PuzzleDataContext);
  const { submittedGuesses, solvedGameData, isGameOver, isGameWon } =
    React.useContext(GameStatusContext);
  const [showAllButtons, setShowAllButtons] = React.useState(false);
  // const [shuffledRows, setShuffledRows] = React.useState(
  //   shuffleGameData({ gameData })
  // );
  const [shuffledRows, setShuffledRows] = React.useState([]);

  React.useEffect(() => {
    setShuffledRows(shuffleGameData({ gameData }));
    setisEndGameModalOpen(false);
  }, [gameData]);
  const [isEndGameModalOpen, setisEndGameModalOpen] = React.useState(false);
  const [gridShake, setGridShake] = React.useState(false);
  const [showConfetti, setShowConfetti] = React.useState(false);

  // use effect to update Game Grid after a row has been correctly solved
  React.useEffect(() => {
    const categoriesToRemoveFromRows = solvedGameData.map(
      (data) => data.category
    );
    const dataLeftForRows = gameData.filter((data) => {
      return !categoriesToRemoveFromRows.includes(data.category);
    });
    if (dataLeftForRows.length > 0) {
      setShuffledRows(shuffleGameData({ gameData: dataLeftForRows }));
    }
  }, [solvedGameData]);

  // Handle End Game!
  React.useEffect(() => {
    if (!isGameOver) {
      return;
    }
    // extra delay for game won to allow confetti to show
    const modalDelay = isGameWon ? 2000 : 250;
    const delayModalOpen = window.setTimeout(() => {
      setisEndGameModalOpen(true);
      //unmount confetti after modal opens
      setShowConfetti(false);
    }, modalDelay);

    if (isGameWon) {
      setShowConfetti(true);
    }

    return () => window.clearTimeout(delayModalOpen);
  }, [isGameOver]);

  const handleToggleDataSource = (aa) => {
    toggleDataSource(aa);
  };
  const renderButtons = (start, end) => {
    return Array.from({ length: end - start + 1 }, (_, i) => todayIndex - (start + i)).map((num) => (
      <button
        key={num}
        onClick={() => handleToggleDataSource(num)}
        className="border border-gray-300 p-2 text-center cursor-pointer bg-gray-100 hover:bg-gray-200 transition-colors duration-300 w-[calc(10%-8px)] min-w-[100px] whitespace-normal break-words"
      >
        #{num}
      </button>
    ));
  };

  return (
    <>
      <h3 className="text-xl text-center mt-4">
        {time}
      </h3>

      <div className={`game-wrapper`}>
        {isGameOver && isGameWon ? (
          <GameWonModal
            open={isEndGameModalOpen}
            submittedGuesses={submittedGuesses}
          />
        ) : (
          <GameLostModal
            open={isEndGameModalOpen}
            submittedGuesses={submittedGuesses}
          />
        )}
        <GameGrid
          gameRows={shuffledRows}
          shouldGridShake={gridShake}
          setShouldGridShake={setGridShake}
        />
        {showConfetti && isGameOver && (
          <div className="grid place-content-center">
            <ConfettiExplosion
              particleCount={100}
              force={0.8}
              duration={2500}
            />
          </div>
        )}
        <Separator />

        {!isGameOver ? (
          <>
            <NumberOfMistakesDisplay />
            <GameControlButtonsPanel
              shuffledRows={shuffledRows}
              setShuffledRows={setShuffledRows}
              setGridShake={setGridShake}
            />
          </>
        ) : (
          <ViewResultsModal />
        )}
      </div>
      {/*<div>*/}
        {/*<h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">The following is the NYT Connections Archive.</h2>*/}
        {/*<p className="text-center mb-4 ">Currently updated to October 31, 2024. Click here to access the <a href="https://www.nytimes.com/games/connections" className="text-blue-500 underline">latest</a> Connections.</p>*/}
      {/*</div>*/}

      <div className="bg-white shadow-md rounded-lg p-6">
        {/*<h2 className="text-2xl font-bold text-center mb-6 text-gray-800">Data Source Toggle</h2>*/}
        <div className="max-w-2xl mx-auto">
        <div className="flex flex-wrap gap-2 mb-4">
          {renderButtons(0, 99)}
        </div>
        {showAllButtons && (
          <div className="flex flex-wrap gap-2 mt-4">
            {renderButtons(100, 499)}
          </div>
        )}
        {!showAllButtons && (
          <div className="text-center mt-4">
            <button
              onClick={() => setShowAllButtons(true)}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
            >
              More
            </button>
          </div>
        )}
        </div>
      </div>
    </>
  );
}

export default Game;
