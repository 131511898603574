import React from "react";
import { puzzleAnswers,puzzleIndex,getLatestPuzzleData,dataChangedEvent } from "../../lib/time-utils";
export const PuzzleDataContext = React.createContext();

function PuzzleDataProvider({ children }) {
  // const [gameData, setGameData] = React.useState(puzzleAnswers);
  const [gameData, setGameData] = React.useState(()=>getLatestPuzzleData());
  // console.log('看看到底更新没',gameData)
  const categorySize = gameData[0].words.length;
  const numCategories = gameData.length;
  const time = gameData[0].time;
  const [todayIndex] = React.useState(puzzleIndex)
  // const name = gameData[0].name;

  // const updateGameData = React.useCallback((event) => {
  //   console.log('监听进来了没', event, event.detail);
  //   setGameData(getLatestPuzzleData(event.detail));
  // }, []);
  //
  // React.useEffect(() => {
  //   window.addEventListener('dataChanged', updateGameData);
  //
  //   return () => {
  //     window.removeEventListener('dataChanged', updateGameData);
  //   };
  // }, [updateGameData]);

  React.useEffect(() => {
    const updatePuzzleData =(aa) => {
      // console.log('监听进来了没',aa,aa.detail)
      setGameData(getLatestPuzzleData(aa.detail));
    };

    window.addEventListener('dataChanged', updatePuzzleData);

    return () => {
      window.removeEventListener('dataChanged', updatePuzzleData);
    };
  }, []);


  return (
    <PuzzleDataContext.Provider
      value={{ gameData, numCategories, categorySize,time,todayIndex }}
    >
      {children}
    </PuzzleDataContext.Provider>
  );
}

export default PuzzleDataProvider;
