import React from "react";
// import { usePuzzleData } from "../../contexts/PuzzleDataContext";

import { PuzzleDataContext } from "../../providers/PuzzleDataProvider";

function ConnectionsHintToday() {
  const { gameData,time } =
    React.useContext(PuzzleDataContext);

  const yellowGroup = gameData?.find(group => group.difficulty === 1);
  const GreenGroup = gameData?.find(group => group.difficulty === 2);
  const BlueGroup = gameData?.find(group => group.difficulty === 3);
  const purpleGroup = gameData?.find(group => group.difficulty === 4);
  return (
    <div>
      <h1>Connections Hint today</h1>
      <p className="text-center">{time}</p>
      <div className="p-8">
        <div className="max-w-4xl mx-auto space-y-8">
          {/* Yellow Section */}
          <div className="space-y-4">
            {/*<FlipCard*/}
            {/*  frontText="SEE HINT"*/}
            {/*  backText={yellowGroup?.hint || "暂无提示"}*/}
            {/*  className="w-full h-16 bg-yellow-300 rounded-md"*/}
            {/*/>*/}
            <FlipCard
              frontText="SEE GROUP"
              backText={yellowGroup?.category}
              className="w-full h-16 bg-yellow-300 rounded-md"
            />
            <div className="grid grid-cols-4 gap-4">
              {yellowGroup?.words?.map((word, index) => (
                <FlipCard
                  key={index}
                  frontText="SEE WORD"
                  backText={word}
                  className="h-16 bg-yellow-300 rounded-md"
                />
              ))}
            </div>
          </div>

          {/* Green Section */}
          <div className="space-y-4">
            {/*<FlipCard*/}
            {/*  frontText="SEE HINT"*/}
            {/*  backText="Green Hint"*/}
            {/*  className="w-full h-16 bg-green-300 rounded-md"*/}
            {/*/>*/}
            <FlipCard
              frontText="SEE GROUP"
              backText={GreenGroup?.category}
              className="w-full h-16 bg-green-300 rounded-md"
            />
            <div className="grid grid-cols-4 gap-4">
              {GreenGroup?.words?.map((word, index) => (
                <FlipCard
                  key={index}
                  frontText="SEE WORD"
                  backText={word}
                  className="h-16 bg-green-300 rounded-md"
                />
              ))}
            </div>
          </div>
          {/* blue Section */}
          <div className="space-y-4">
            {/*<FlipCard*/}
            {/*  frontText="SEE HINT"*/}
            {/*  backText="Blue Hint"*/}
            {/*  className="w-full h-16 bg-sky-500 rounded-md"*/}
            {/*/>*/}
            <FlipCard
              frontText="SEE GROUP"
              backText={BlueGroup?.category}
              className="w-full h-16 bg-sky-500 rounded-md"
            />
            <div className="grid grid-cols-4 gap-4">
              {BlueGroup?.words?.map((word, index) => (
                <FlipCard
                  key={index}
                  frontText="SEE WORD"
                  backText={word}
                  className="h-16 bg-sky-500 rounded-md"
                />
              ))}
            </div>
          </div>
          {/* purple Section */}
          <div className="space-y-4">
            {/*<FlipCard*/}
            {/*  frontText="SEE HINT"*/}
            {/*  backText="purple Hint"*/}
            {/*  className="w-full h-16 bg-fuchsia-500 rounded-md"*/}
            {/*/>*/}
            <FlipCard
              frontText="SEE GROUP"
              backText={purpleGroup?.category}
              className="w-full h-16 bg-fuchsia-500 rounded-md"
            />
            <div className="grid grid-cols-4 gap-4">
              {purpleGroup?.words?.map((word, index) => (
                <FlipCard
                  key={index}
                  frontText="SEE WORD"
                  backText={word}
                  className="h-16 bg-fuchsia-500 rounded-md"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-md">
        <h1 className="text-2xl font-bold mb-4 text-gray-800">Connections Hint Today</h1>
        <p className="mb-4 text-gray-600">
          If you’re diving into the world of word games, you’ve probably encountered the NYT Connections game. This engaging puzzle challenges players to categorize 16 words into four distinct groups based on a shared theme. Some days can be tricky, but don’t worry—our <strong>Connections hint today</strong> page is here to help you out!
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2 text-gray-800">How to Use Our Connections Hint Today</h2>
        <p className="mb-4 text-gray-600">
          Not every player wants a full answer for today’s NYT Connections puzzle, so we’ve designed this page for you! Here, you’ll find one hint for each category, allowing you to piece together the puzzle yourself. Bookmark this page to access quick hints whenever you need them.
        </p>

        <h3 className="font-semibold text-gray-700 mb-2">Searching for Quick Hints</h3>
        <p className="mb-4 text-gray-600">
          If you’re looking for a subtle nudge without revealing the full answers, our <strong>Connections hint today</strong> page features color-coded hints based on NYT Connections colors, organized from easier to more challenging. This way, you can easily navigate through your options.
        </p>

        <h3 className="font-semibold text-gray-700 mb-2">Accessing Group Names</h3>
        <p className="mb-4 text-gray-600">
          If you need the category titles for today’s puzzle, simply tap the “SEE GROUP” button. This will display the names of each category, perfect for when you’re unsure about a specific word.
        </p>

        <h3 className="font-semibold text-gray-700 mb-2">Finding Complete Answers</h3>
        <p className="mb-4 text-gray-600">
          Stuck and need the full breakdown? We’ve got you covered with today’s complete NYT Connections answers. This section is saved for the end to maintain some suspense while also helping those who want to solve the puzzle independently. Click “SEE WORD” to see which words belong to each group, color-coded for your convenience.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2 text-gray-800">What is Connections by The New York Times?</h2>
        <p className="mb-4 text-gray-600">
          NYT Connections is reminiscent of the childhood game Categories, but with a twist. Instead of starting with a letter, you begin with 16 words that need to be organized into four groups. You’ll have to avoid making four mistakes when categorizing the words, as each wrong guess counts against you. The game provides feedback through color-coded responses, helping you track your progress.
        </p>

        <h3 className="font-semibold text-gray-700 mb-2">NYT Connections Colors Explained</h3>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li>🟡 <strong>Yellow</strong>: Easy answers.</li>
          <li>🟢 <strong>Green</strong>: Fairly easy guesses.</li>
          <li>🔵 <strong>Blue</strong>: Trivia or less familiar answers.</li>
          <li>🟣 <strong>Purple</strong>: The most challenging guesses, often involving niche knowledge.</li>
        </ul>
        <p className="mb-4 text-gray-600">
          Once you’ve completed the puzzle, share your solution grid with friends to see how you stack up!
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-2 text-gray-800">Tips & Strategies for Playing Connections</h2>
        <p className="mb-4 text-gray-600">
          Improving your NYT Connections skills requires some strategic thinking and a bit of trivia knowledge. Here are some tips to enhance your game:
        </p>

        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li><strong>Brush Up on Trivia</strong>: Knowing common trivia topics can help, especially with medium-difficulty blue and green groups. Consider exploring trivia books or games to expand your knowledge.</li>
          <li><strong>Eliminate Options Quickly</strong>: Before making guesses, assess the words you have and try to identify potential categories. Getting the easy ones out of the way can help you narrow down your choices.</li>
        </ul>

        <p className="mb-4 text-gray-600">
          For those eager to enhance their gameplay, keep checking back for our <strong>Connections hint today</strong> updates! Happy puzzling!
        </p>
      </div>
    </div>
  );
}
function FlipCard({ frontText, backText, className = "" }) {
  const [isFlipped, setIsFlipped] = React.useState(false)

  return (
    <div
      className={`relative cursor-pointer ${className}`}
      style={{ perspective: "1000px" }}
      onClick={() => setIsFlipped(!isFlipped)}
    >
      <div
        className="absolute inset-0 transition-transform duration-500"
        style={{
          transformStyle: "preserve-3d",
          transform: isFlipped ? "rotateY(180deg)" : ""
        }}
      >
        {/* Front */}
        <div
          className="absolute inset-0 flex items-center justify-center rounded-lg shadow-md"
          style={{ backfaceVisibility: "hidden" }}
        >
          <span className="font-bold text-gray-800 text-center">{frontText}</span>
          <div className="absolute right-0 bottom-0 w-4 h-4 bg-black/5 rounded-tl-lg" />
        </div>

        {/* Back */}
        <div
          className="absolute inset-0 flex items-center justify-center rounded-lg shadow-md"
          style={{
            backfaceVisibility: "hidden",
            transform: "rotateY(180deg)"
          }}
        >
          <span className="font-bold text-gray-800">{backText}</span>
          <div className="absolute right-0 bottom-0 w-4 h-4 bg-black/5 rounded-tl-lg" />
        </div>
      </div>
    </div>
  )
}

export default ConnectionsHintToday;
