import React from "react";
// import ReactDOM from 'react-dom';
import { createRoot } from "react-dom/client";
import { HashRouter } from 'react-router-dom';

import App from "./components/App";
import "./reset.css";
import "./styles.css";
import "./index.css";

// const root = createRoot(document.querySelector("#root"));
// root.render(<App />);
ReactDOM.render(
  <HashRouter>
    <App />
  </HashRouter>,
  document.getElementById('root')
);
