import {
  addDays,
  differenceInDays,
  formatISO,
  parseISO,
  startOfDay,
  startOfToday,
  startOfYesterday,
} from "date-fns";

import queryString from "query-string";

import { CONNECTION_GAMES } from "./data";
import { CONNECTION_GAMES1 } from "./originData";

export const getToday = () => startOfToday();
export const getYesterday = () => startOfYesterday();

// October 2023 Game Epoch
// https://stackoverflow.com/questions/2552483/why-does-the-month-argument-range-from-0-to-11-in-javascripts-date-constructor
export const firstGameDate = new Date(2023, 6, 12);
export const periodInDays = 1;

export const getLastGameDate = (today) => {
  const t = startOfDay(today);
  let daysSinceLastGame = differenceInDays(t, firstGameDate) % periodInDays;
  return addDays(t, -daysSinceLastGame);
};

export const getNextGameDate = (today) => {
  return addDays(getLastGameDate(today), periodInDays);
};

export const isValidGameDate = (date) => {
  if (date < firstGameDate || date > getToday()) {
    return false;
  }

  return differenceInDays(firstGameDate, date) % periodInDays === 0;
};

export const getIndex = (gameDate) => {
  // let start = firstGameDate;
  // let index = 0;
  // do {
  //   index++;
  //   start = addDays(start, periodInDays);
  // } while (start <= gameDate);
  // if(gameDate>='20241024'){
  //   return 500
  // }else{
  // if(!index){
    return 0
  // }else{
  //   return index;
  // }
  // }
};

export const getPuzzleOfDay = (index) => {
  // console.log('调用了getPuzzleOfDay',index)
  if (index < 0) {
    throw new Error("Invalid index");
  }
  // console.log('初始',CONNECTION_GAMES1[index % CONNECTION_GAMES1.length])
  let board = CONNECTION_GAMES1[index % CONNECTION_GAMES1.length].board;
  let arr =[]
  for (let key in board) {
    arr.push({
      category:key,
      words:board[key].members,
      difficulty:board[key].level+1,
      time:new Date(CONNECTION_GAMES1[index % CONNECTION_GAMES1.length].createdAt).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }),
      name:CONNECTION_GAMES1[index % CONNECTION_GAMES1.length].name
      }
      )
  }
  // return CONNECTION_GAMES[index % CONNECTION_GAMES.length];
  return arr;
};

// 创建一个自定义事件，并在事件中包含数据
export const createDataChangedEvent = (data) => {
  return new CustomEvent('dataChanged', { detail: data });
};
export const toggleDataSource = (aa) => {
  // console.log('点击了',aa)
  // getSolution(aa)
  getLatestPuzzleData(aa)
  const dataChangedEvent = createDataChangedEvent(aa);
  window.dispatchEvent(dataChangedEvent);
};


export const getSolution = (gameDate) => {
  const nextGameDate = getNextGameDate(gameDate);
  // const index = getIndex(gameDate);
  const index = gameDate;
  const puzzleOfTheDay = getPuzzleOfDay(index);
  // 在这里触发自定义事件
  const today = new Date().toLocaleDateString('en-GB').split('/').join('-');
  const todayIndex = CONNECTION_GAMES1.findIndex(game => {
    return game.date === today;
  });
  return {
    puzzleAnswers: puzzleOfTheDay,
    puzzleGameDate: gameDate,
    puzzleIndex: todayIndex,
    dateOfNextPuzzle: nextGameDate.valueOf(),
  };
};
export const getLatestPuzzleData = (index) => {
  // 这里获取最新的谜题数据
  // const index = getCurrentPuzzleIndex(); // 假设这个函数可以获取当前的索引
  // console.log('getLatestPuzzleData-index',index)
  if(!index){
    const today = new Date().toLocaleDateString('en-GB').split('/').join('-');
    const todayPuzzle = CONNECTION_GAMES1.find(game => {
      return game.date === today;
    });
    const todayIndex = CONNECTION_GAMES1.findIndex(game => {
      return game.date === today;
    });
    return getPuzzleOfDay(todayIndex >= 0 ? todayIndex : 0);
  }else{
    return getPuzzleOfDay(index)
  }

};

export const getGameDate = () => {
  if (getIsLatestGame()) {
    return getToday();
  }

  const parsed = queryString.parse(window.location.search);
  try {
    const d = startOfDay(parseISO(parsed.d?.toString()));
    if (d >= getToday() || d < firstGameDate) {
      setGameDate(getToday());
    }
    return d;
  } catch (e) {
    console.log(e);
    return getToday();
  }
};

export const setGameDate = (d) => {
  try {
    if (d < getToday()) {
      window.location.href = "/?d=" + formatISO(d, { representation: "date" });
      return;
    }
  } catch (e) {
    console.log(e);
  }
  window.location.href = "/";
};

export const getIsLatestGame = () => {
  // https://github.com/cwackerfuss/react-wordle/pull/505
  const parsed = queryString.parse(window.location.search);
  return parsed === null || !("d" in parsed);
};

export const { puzzleAnswers, puzzleGameDate, puzzleIndex, dateOfNextPuzzle } =
  getSolution(getGameDate());
